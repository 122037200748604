import GoogleMap from "../GoogleMap/GoogleMap";
import "./MapCard.scss";
import poBox from "../../assets/icons/poBox.svg";
import ContactButton from "../ContactButton/ContactButton";
import { Link } from "react-router-dom";

export default function MapCard() {
  const location = {
    address: "708 E Main St, Waverly, TN 37185",
    lat: 36.08263454444608,
    lng: -87.77422919369022,
  };

  return (
    <div className="mapCard">
      <section className="mapCard__information">
        <p className="information__address">708 E Main ST. Waverly, TN 37185</p>
        <p className="information__phrase">
          Located just across from Carol's Diner, we're available for the entire
          Waverly community.
        </p>
        <div className="information__container">
          <img className="information__icon" src={poBox} alt="P.O. Box" />
          <p className="information__poBox">P.O. Box 614 Waverly TN 37185</p>
        </div>
        <Link
          className="information__link"
          to="https://maps.app.goo.gl/qBk4Nc5J2m7bL6fw8"
        >
          <ContactButton
            classtitle="button button__googleMap"
            content="Take me there"
          />
        </Link>
      </section>
      <section className="mapCard__googleContainer">
        <GoogleMap location={location} zoomLevel={17} />
      </section>
    </div>
  );
}
