import { Link } from "react-router-dom";
import ContactButton from "../../components/ContactButton/ContactButton";
import HeroSmall from "../../components/HeroSmall/HeroSmall";
import "./CalendarPage.scss";

export default function CalendarPage() {
  return (
    <div className="calendarPage">
      <HeroSmall extraClass="heroSmall__calendar" content="Upcoming events" />
      <h2 className="calendarPage__disclaimer">
        This page isn't really suited for mobile. For a better experience,
      </h2>
      <Link
        to="https://ffwbcw.chmeetings.com/Calendar/E791F5628F15F64A"
        className="calendarPage__link"
      >
        <ContactButton
          classtitle="button button__calendar"
          content="click here"
        />
      </Link>
      <section className="calendarPage__wrapper">
        <iframe
          src="https://ffwbcw.chmeetings.com/Calendar/E791F5628F15F64A?isEmbed=true&color=139EAB&defaultView=month"
          height="768"
          loading="lazy"
          style={{
            borderWidth: "0px",
            width: "100%",
            maxHeight: "60rem",
          }}
          title="Calendar"
        ></iframe>
      </section>
    </div>
  );
}
