import FAQCard from "../FAQCard/FAQCard";
import "./FAQList.scss";

export default function FAQList() {
  return (
    <div className="FAQList">
      <h2 className="FAQList__header">What to expect</h2>
      <FAQCard
        question='What is a "free will" Baptist?'
        answer="To quote from our statement of faith, “As Free Will Baptists, we believe in Free Grace, Free Will, and Free Salvation. We believe God’s grace is freely given to all people everywhere, not just to select people, and invites them to believe in the Lord Jesus Christ for salvation and forgiveness of sins. We believe that people have the free will to respond to God’s offer of salvation of their own choice, not by force from God or man. They maintain free will after salvation, being assured of God’s salvation unless they purposely and consciously reject Him, committing the unpardonable sin and stamping underfoot the blood of Christ. Lastly, we believe this salvation is the free gift of God. We can offer nothing in exchange nor contribute anything, save to serve Him gladly and ascribe to Him the worship He is due.”"
      />
      <FAQCard
        question="What should I wear?"
        answer="We don’t set many expectations on what you have to wear. Most of our congregation comes in wearing casual to business casual dress. You could come in with sandals, jeans, and a t-shirt if you’d like. What’s important is that you come in what you feel comfortable in, whether that’s jeans and a t-shirt or a suit and tie."
      />
      <FAQCard
        question="What music do you sing?"
        answer="Our approach to music is a “blended” approach, meaning we aim for songs that are biblical, and teach something about God, whether 500, 50, or 5 years old, quick or slow. We sing a variety of classic hymns and modern contemporary songs."
      />
      <FAQCard
        question="How can I get in touch with someone from the church?"
        answer="There’s a few ways to reach out. We welcome you to come early or stay after service to get to know some of our congregation or our pastor. We would all love to get to know you. If you’re not able to make it to the service for any reason, you can find us on Facebook at First Free Will Baptist Church Waverly, join us for YouTube livestreams, or go to our contact page to send us an email."
      />
    </div>
  );
}
