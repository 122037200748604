import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Homepage from "./pages/Homepage/Homepage";
import AboutStaff from "./pages/AboutStaff/AboutStaff";
import MinistriesPage from "./pages/MinistriesPage/MinistriesPage";
import AboutBeliefs from "./pages/AboutBeliefs/AboutBeliefs";
import AboutHistory from "./pages/AboutHistory/AboutHistory";
import ServicesPage from "./pages/ServicesPage/ServicesPage";
import ContactPage from "./pages/ContactPage/ContactPage";
import NotFound from "./pages/NotFound/NotFound";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import ScrollFix from "./components/ScrollFix/ScrollFix";
import CalendarPage from "./pages/Calendar/CalendarPage";
import AboutPartners from "./pages/AboutPartners/AboutPartners";

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollFix />
        <Navbar />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/about-staff" element={<AboutStaff />} />
          <Route path="/about-ministries" element={<MinistriesPage />} />
          <Route path="/about-beliefs" element={<AboutBeliefs />} />
          <Route path="/about-history" element={<AboutHistory />} />
          <Route path="/about-partners" element={<AboutPartners />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/calendar" element={<CalendarPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
