import React from "react";
import "./AboutPartners.scss";
import kristen from "../../assets/images/kristenturberville.jpg";
import agape from "../../assets/images/agapeLoveWorks.png";
import HeroSmall from "../../components/HeroSmall/HeroSmall";
import nafwb from "../../assets/images/fwbLogo2.png";
import ciulogo from "../../assets/images/ciuLogo.png";

export default function AboutPartners() {
  return (
    <div className="aboutPartners">
      <HeroSmall extraClass="heroSmall__partners" content="Ministry Partners" />
      <ul className="aboutPartners__list">
        <li className="aboutPartners__partner">
          <div className="partner__container">
            <img
              src={nafwb}
              alt="National Association of Free Will Baptists Logo"
              className="partner__img"
            />
          </div>
          <div className="partner__content">
            <h3 className="partner__title">
              National Association of Free Will Baptists
            </h3>
            <p className="partner__desc">
              We lovingly fellowship within the National Association of Free
              Will Baptist churches and are joined in accountability to the
              Western Quarterly of the Cumberland Association.
            </p>
          </div>
        </li>
        <li className="aboutPartners__partner">
          <div className="partner__container">
            <img
              src={kristen}
              alt="Kristen Turbeville Headshot"
              className="partner__img"
            />
          </div>
          <div className="partner__content">
            <h3 className="partner__title">Consider the lilies counseling</h3>
            <h4 className="partner__subtitle">
              Kristen Turbeville{" "}
              <span className="partner__subtitle--secondary">lpc-mhsp</span>
            </h4>
            <p className="partner__desc">
              "I hope to create a space where you feel able to fully express
              yourself, without judgment or an emotionally-charged response.
              Opening up to someone, especially a stranger, can be scary. I
              believe that healing can come when we trust others enough with our
              problems to ask for help. Everyone at some point in their life
              could use someone to help them process the difficult experiences
              that life sometimes holds. I would like to explore these issues
              together, in a setting where you feel comfortable being heard and
              understood. I primarily use Cognitive-Behavioral Therapy, an
              approach that emphasizes change in thinking patterns and
              behaviors. This approach can be beneficial in tackling many issues
              in life. Additionally, my dog, Hank is in the process of getting
              trained to be a therapy dog! He may sit in on some of our
              sessions."
            </p>
            <p className="partner__location">Dickson, Tennessee</p>
          </div>
        </li>
        <li className="aboutPartners__partner">
          <div className="partner__container partner__container--agape">
            <img
              src={agape}
              alt="Agape Counseling Logo"
              className="partner__img partner__img--agape"
            />
          </div>
          <div className="partner__content">
            <h3 className="partner__title">agape nashville</h3>
            <h4 className="partner__subtitle">
              agape{" "}
              <span className="partner__subtitle--secondary">love works</span>
            </h4>
            <p className="partner__desc">
              AGAPE embraces people in need, people who often have nowhere else
              to turn. From addiction to broken families, mental illness to
              spiritual despair, neglect to abuse, our professional therapists,
              social workers, and support staff receive with open arms those who
              cry out.
            </p>
            <p className="partner__desc">
              AGAPE provides counseling, crisis support, adoption and foster
              care services, pregnancy-maternity care, emergency domestic
              violence shelter, and court advocacy assistance. At AGAPE, we know
              love works because we see it in action each and every day.
            </p>
            <p className="partner__location">Nashville, Tennessee</p>
          </div>
        </li>
        <li className="aboutPartners__partner">
          <div className="partner__container partner__container--ciu">
            <img
              src={ciulogo}
              alt="Columbia International University Logo"
              className="partner__img partner__img--ciu"
            />
          </div>
          <div className="partner__content">
            <h3 className="partner__title">
              Columbia International University
            </h3>
            <p className="partner__desc">
              We are excited to partner with CIU to offer a discount to tuition
              for church staff and members. This scholarship is a $250-$500 per
              semester discount and is renewable as long as the student is a
              part of the church and stays in good academic standing. Students
              are eligible for additional CIU scholarships, and grants, state,
              and/or federal aid. This scholarship can be applied towards any
              degree program, on campus or online, including doctoral courses.
            </p>
          </div>
        </li>
      </ul>
    </div>
  );
}
