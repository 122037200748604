import MapPin from "../MapPin/MapPin";
import "./GoogleMap.scss";
import GoogleMapReact from "google-map-react";
import MapText from "../MapText/MapText";

interface Location {
  address: string;
  lat: number;
  lng: number;
}

interface Props {
  location: Location;
  zoomLevel: number;
}

export default function GoogleMap({ location, zoomLevel }: Props) {
  const googleKey = process.env.REACT_APP_GOOGLE_MAP as string;

  return (
    <div className="googleMap">
      <GoogleMapReact
        bootstrapURLKeys={{ key: googleKey }}
        defaultCenter={location}
        defaultZoom={zoomLevel}
      >
        <MapPin
          lat={location.lat}
          lng={location.lng}
          address={location.address}
        />
        <MapText lat={location.lat + 0.0001} lng={location.lng + 0.0001} />
      </GoogleMapReact>
    </div>
  );
}
