import ContactButton from "../ContactButton/ContactButton";
import "./Footer.scss";
import waverlyLogo from "../../assets/images/FirstWaverlyLogo.png";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer__left">
        <Link to="/" className="footer__wrapper">
          <img
            className="footer__logo"
            src={waverlyLogo}
            alt="First Free Will Baptist Church of Waverly Logo"
          />
        </Link>
        <p className="footer__copyright">
          © 2023 First Free Will Baptist Church of Waverly. All rights reserved.
        </p>
      </div>
      <div className="footer__content">
        <section className="footer__content--left">
          <h4 className="footer__header">Location</h4>
          <p className="footer__address">708 E. Main St. Waverly, TN 37185</p>
          <p className="footer__poBox">P.O. Box 614 Waverly, TN 37185</p>
        </section>
        <section className="footer__content--mid">
          <h4 className="footer__header">Service Times</h4>
          <p className="footer__time footer__time--sun">
            Sunday: 10 AM & 11 AM
          </p>
          <p className="footer__time footer__time--wed">
            Wednesday: 6 PM & 7 PM
          </p>
        </section>
        <section className="footer__content--right">
          <h4 className="footer__header">Quick Links</h4>
          <Link to="/about-staff" className="footer__link footer__link--about">
            About us
          </Link>
          <Link to="/services" className="footer__link footer__link--services">
            Services
          </Link>
          <Link
            to="https://ffwbcw.chmeetings.com/Account/Login"
            className="footer__link footer__link--members"
            target="_blank"
            rel="noopener noreferrer"
          >
            Members
          </Link>
          <Link
            to="https://ffwbcw.chmeetings.com//Giving/E791F5628F15F64A"
            className="footer__link footer__link--services"
            target="_blank"
            rel="noopener noreferrer"
          >
            Give
          </Link>
        </section>
      </div>
      <div className="footer__right">
        <Link to="/contact" className="footer__link footer__link--contact">
          <ContactButton
            content="Contact Us"
            classtitle="button button__footer"
          />
        </Link>
        <section className="footer__socials">
          <Link
            className="contactPage__link"
            to="https://www.facebook.com/FFWBC702"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i
              className="fa-brands fa-facebook fa-2xl"
              style={{ color: "#27bdbe" }}
            ></i>
          </Link>
          <Link
            className="contactPage__link"
            to="https://www.youtube.com/@waverlyfirstfreewillbaptistchu"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i
              className="fa-brands fa-youtube fa-2xl"
              style={{ color: "#20bdbe" }}
            ></i>
          </Link>
        </section>
      </div>
    </footer>
  );
}
