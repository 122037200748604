import Button from "../../components/ContactButton/ContactButton";
import "./Homepage.scss";
import chLogoFull from "../../assets/images/Chmeetings-logoFULL.png";
import ContactButton from "../../components/ContactButton/ContactButton";
import { Link } from "react-router-dom";

export default function Homepage() {
  return (
    <main className="homepage">
      <div className="hero">
        <section className="hero__content">
          <div className="hero__phrase">
            <h1 className="hero__text">it is not about you</h1>
            <h1 className="hero__text">it is not about me</h1>
            <section className="hero__text--last">
              <h1 className="hero__text">it is about</h1>
              <span className="hero__text hero__text--big">him</span>
            </section>
          </div>
          <Link to="/about-beliefs" className="hero__link">
            <Button content="learn more" classtitle="button button__hero" />
          </Link>
        </section>
        <section className="hero__overlay"></section>
      </div>
      <div className="church-cards">
        <section className="church-cards__header">
          <span className="church-cards__phrase">A church</span>
          <span className="church-cards__phrase church-cards__phrase--accent">
            dedicated
          </span>
          <span className="church-cards__phrase">to</span>
          <span className="church-cards__phrase">the</span>
          <span className="church-cards__phrase church-cards__phrase--accent">
            waverly
          </span>
          <span className="church-cards__phrase">community</span>
        </section>
        <section className="church-cards__container">
          <Link to="/services" className="church-cards__link">
            <div className="church-cards__card church-cards__card--left">
              <p className="church-cards__text">Service Times</p>
            </div>
          </Link>
          <Link to="/about-ministries" className="church-cards__link">
            <div className="church-cards__card church-cards__card--mid">
              <p className="church-cards__text">Our Ministries</p>
            </div>
          </Link>
          <Link
            to="https://ffwbcw.chmeetings.com//Giving/E791F5628F15F64A"
            className="church-cards__link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="church-cards__card church-cards__card--right">
              <p className="church-cards__text church-cards__text--last">
                Give online
              </p>
            </div>
          </Link>
        </section>
      </div>
      <div className="external-links">
        <Link
          to="https://ffwbcw.chmeetings.com/Account/Login"
          className="external-links__link"
          target="_blank"
          rel="noopener noreferrer"
        >
          <section className="external-links__card external-links__card--left">
            <div className="external-links__overlay"></div>
            <div className="external-links__wrapper">
              <p className="external-links__title">
                More member features through partnership with
              </p>
              <img
                src={chLogoFull}
                alt="Ch-Meetings logo"
                className="external-links__chmeetings"
              />
            </div>
          </section>
        </Link>
        <Link
          to="https://www.facebook.com/FFWBC702/"
          className="external-links__link"
          target="_blank"
          rel="noopener noreferrer"
        >
          <section className="external-links__card external-links__card--right">
            <div className="external-links__overlay external-links__overlay--facebook"></div>
            <div className="external-links__wrapper">
              <p className="external-links__title">Follow us on</p>
              <h3 className="external-links__facebook">Facebook</h3>
            </div>
          </section>
        </Link>
      </div>
      <div className="cta">
        <Link to="/services" className="cta__link">
          <ContactButton
            content="Worship with us"
            classtitle="button button__cta"
          />
        </Link>
        <section className="cta__container">
          <p className="cta__text">
            We’re just across from Carol’s Diner. If you are visiting town or
            our church, we would love to get to know you and pray for you.
          </p>
          <h3 className="cta__address">708 E Main St., Waverly, TN 37185</h3>
        </section>
        <section className="cta__lines">
          <div className="cta__line cta__line--top"></div>
          <div className="cta__line cta__line--bottom"></div>
        </section>
      </div>
    </main>
  );
}
