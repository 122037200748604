import { useState } from "react";
import "./MinistryCard.scss";

export default function MinistryCard(props: {
  title: string;
  desc: string;
  extraClass: string;
  cardClass: string;
}) {
  const [cardOpen, setCardOpen] = useState(false);

  const handleCardClick = () => {
    if (window.innerWidth < 743) {
      setCardOpen(!cardOpen);
    } else {
      return;
    }
  };
  return (
    <div className={props.cardClass}>
      <section
        className={
          cardOpen
            ? "ministryCard__text"
            : "ministryCard__text ministryCard__text--hidden"
        }
      >
        <h3 className="ministryCard__header">{props.title}</h3>
        <p className="ministryCard__desc">{props.desc}</p>
      </section>
      <div
        className={`ministryCard__img ministryCard__img--${props.extraClass}`}
        onClick={handleCardClick}
      ></div>
    </div>
  );
}
