import HeroSmall from "../../components/HeroSmall/HeroSmall";
import "./AboutBeliefs.scss";
import wallCross from "../../assets/images/Cross.jpg";
import fwbLogo from "../../assets/images/nafwb.jpg";

export default function AboutBeliefs() {
  return (
    <div className="aboutBeliefs">
      <HeroSmall extraClass="heroSmall__about" content="our beliefs" />
      <section className="aboutBeliefs__section aboutBeliefs__section--top">
        <div className="aboutBeliefs__container">
          <h2 className="aboutBeliefs__header">
            What we{" "}
            <span className="aboutBeliefs__header aboutBeliefs__header--accent">
              believe
            </span>
          </h2>
          <p className="aboutBeliefs__text">
            We believe that the Scriptures are the divine rule for our beliefs
            and practices in all of life. We believe the Scriptures are breathed
            out by God and recorded by men as moved by the Spirit, thereby being
            the inerrant and infallible word of God while showing the
            experience, education, and personality of the authors.
          </p>
          <p className="aboutBeliefs__text">
            We believe in one God in three persons. The Father, eternal,
            invisible, and only wise maker of all things visible and invisible.
            The Son, the eternally begotten but not made as through him all is
            made. He came down from heaven and incarnated as a man, was born of
            the virgin Mary, was crucified, buried, and rose the third day for
            us and our salvation. He ascended to heaven and will come again to
            judge the living and the dead, after which His kingdom will never
            end. Though incarnated as a man He is the same essence as the
            Father, God from God, light from light, true God from true God. The
            Spirit, the giver of life and indweller of believers, has spoken
            through the prophets in past times and now through the inspired
            Scriptures. He is the one who walks alongside, guiding believers
            from the point of salvation through their walk in this world and
            until the point they are freed from sin eternally.
          </p>
        </div>
        <img
          src={wallCross}
          alt="A cross on a wall"
          className="aboutBeliefs__cross"
        />
      </section>
      <section className="aboutBeliefs__section aboutBeliefs__section--bottom">
        <div className="aboutBeliefs__container">
          <p className="aboutBeliefs__text">
            We believe in one church, the bride and body of Christ. The church
            is the hands and feet of Christ on this earth. Though divided in
            denominations, we realize these are man-made distinctions, not
            divine, so while we belong to the Free Will Baptist denomination, we
            strive to maintain the unity of the church through loving one
            another.
          </p>
          <p className="aboutBeliefs__text">
            We believe that all people are made in the image of God, regardless
            of male or female, socio-economic class, nationality, ethnicity,
            age, disability, or any other God-ordained distinction. As such, we
            resist any injustice and prejudice while promoting the Spiritual,
            Mental, Emotional, and Physical wellbeing of all.
          </p>
          <div className="aboutBeliefs__logoWrapper aboutBeliefs__logoWrapper--mobile">
            <img
              src={fwbLogo}
              alt="Free Will Baptist denomination logo"
              className="aboutBeliefs__fwbLogo aboutBeliefs__fwbLogo--mobile"
            />
          </div>
          <p className="aboutBeliefs__text">
            As Free Will Baptists, we believe in Free Grace, Free Will, and Free
            Salvation. We believe God’s grace is freely given to all people
            everywhere, not just to select people, and invites them to believe
            in the Lord Jesus Christ for salvation and forgiveness of sins. We
            believe that people have the free will to respond to God’s offer of
            salvation of their own choice, not by force from God or man. They
            maintain free will after salvation, being assured of God’s salvation
            unless they purposely and consciously reject Him, committing the
            unpardonable sin and stamping underfoot the blood of Christ. Lastly,
            we believe this salvation is the free gift of God. We can offer
            nothing in exchange nor contribute anything, save to serve Him
            gladly and ascribe to Him the worship He is due.
          </p>
        </div>
        <div className="aboutBeliefs__logoWrapper">
          <img
            src={fwbLogo}
            alt="Free Will Baptist denomination logo"
            className="aboutBeliefs__fwbLogo"
          />
        </div>
      </section>
    </div>
  );
}
