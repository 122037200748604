export const MembersItems = [
  {
    title: "CHMeetings",
    path: "https://ffwbcw.chmeetings.com/Account/Login",
    cName: "dropdown__link",
    external: true,
  },
  {
    title: "Calendar",
    path: "/calendar",
    cName: "dropdown__link dropdown__link--desktop",
  },
  {
    title: "Calendar",
    path: "https://ffwbcw.chmeetings.com/Calendar/E791F5628F15F64A",
    cName: "dropdown__link dropdown__link--mobile",
  },
];
