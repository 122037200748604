import HeroSmall from "../../components/HeroSmall/HeroSmall";
import "./ContactPage.scss";
import emailjs from "@emailjs/browser";
import { useRef } from "react";
import MapCard from "../../components/MapCard/MapCard";
import { Link } from "react-router-dom";

export default function ContactPage() {
  const pubKey = process.env.REACT_APP_EMAIL_PUBLIC as string;
  emailjs.init(pubKey);

  const formRef = useRef<HTMLFormElement | null>(null);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (formRef.current) {
      emailjs
        .sendForm("service_4t1zix5", "template_ouxfzd8", formRef.current)
        .then(
          (result) => {
            console.log(result.text);
            alert("Your message has been sent. You'll hear from us soon!");
            (formRef.current as HTMLFormElement).reset();
          },
          (error) => {
            console.log(error.text);
            alert(`There was an error. ${error.text}`);
          }
        );
    }
  };

  return (
    <div className="contactPage">
      <HeroSmall
        content="get in touch with us"
        extraClass="heroSmall__contact"
      />
      <section className="contactPage__contact">
        <form
          onSubmit={handleSubmit}
          ref={formRef}
          className="contactPage__form"
        >
          <h2 className="form__header">Send us a message:</h2>
          <input
            type="text"
            id="name"
            name="user_name"
            placeholder="Your Full Name"
            className="form__textfield"
            required
          />
          <input
            type="email"
            id="email"
            name="user_email"
            placeholder="Your Email"
            className="form__textfield"
            required
          />
          <input
            type="text"
            id="subject"
            name="subject"
            placeholder="Subject"
            className="form__textfield"
            required
          />
          <textarea
            className="form__textfield form__textfield--large"
            name="message"
            id="message"
            cols={30}
            rows={10}
            placeholder="Message"
            required
          ></textarea>
          <button id="submit" name="submit" className="button button__form">
            Send message
          </button>
        </form>
        <div className="contactPage__information">
          <section className="contactPage__address">
            <p className="contactPage__subheader">Address</p>
            <p className="contactPage__details">
              708 E. Main St. Waverly, TN 37185
            </p>
          </section>
          <section className="contactPage__otherDetails">
            <p className="contactPage__subheader">Contact Details</p>
            <p className="contactPage__details">
              P.O. Box 614 Waverly TN 37185
            </p>
            <p className="contactPage__details">info@ffwbcw.org</p>
          </section>
          <section className="contactPage__socials">
            <p className="contactPage__subheader">Follow our social medias!</p>
            <Link
              className="contactPage__link"
              to="https://www.facebook.com/FFWBC702"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="contactPage__facebook">
                <i
                  className="fa-brands fa-facebook fa-xl"
                  style={{ color: "#27bdbe" }}
                ></i>
                <i
                  className="fa-brands fa-facebook fa-2xl"
                  style={{ color: "#27bdbe" }}
                ></i>
                <span className="contactPage__tag">
                  First Free Will Baptist Church Waverly
                </span>
              </div>
            </Link>
            <Link
              className="contactPage__link"
              to="https://www.youtube.com/@waverlyfirstfreewillbaptistchu"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="contactPage__youtube">
                <i
                  className="fa-brands fa-youtube fa-xl"
                  style={{ color: "#20bdbe" }}
                ></i>
                <i
                  className="fa-brands fa-youtube fa-2xl"
                  style={{ color: "#20bdbe" }}
                ></i>
                <span className="contactPage__tag">
                  Waverly First Free Will Baptist Church
                </span>
              </div>
            </Link>
          </section>
        </div>
      </section>
      <MapCard />
    </div>
  );
}
