export const AboutItems = [
  {
    title: "Staff",
    path: "/about-staff",
    cName: "dropdown__link",
  },
  {
    title: "Our History",
    path: "/about-history",
    cName: "dropdown__link",
  },
  {
    title: "Our Beliefs",
    path: "/about-beliefs",
    cName: "dropdown__link",
  },
  {
    title: "Ministries",
    path: "/about-ministries",
    cName: "dropdown__link",
  },
  {
    title: "Partners",
    path: "/about-partners",
    cName: "dropdown__link",
  },
];
