import "./FAQCard.scss";

export default function FAQCard(props: { question: string; answer: string }) {
  return (
    <div className="FAQCard">
      <section className="FAQCard__line"></section>
      <h3 className="FAQCard__question">{props.question}</h3>
      <p className="FAQCard__answer">{props.answer}</p>
    </div>
  );
}
