import "./Navbar.scss";
import waverlyLogo from "../../assets/images/FirstWaverlyLogo.png";
import { Link } from "react-router-dom";
import ContactButton from "../ContactButton/ContactButton";
import { useState, useEffect } from "react";
import Dropdown from "../Dropdown/Dropdown";
import { AboutItems } from "../Dropdown/AboutItems";
import { MembersItems } from "../Dropdown/MembersItems";

declare global {
  interface Window {
    google: {
      translate: {
        TranslateElement: {
          new (
            options: {
              pageLanguage: string;
              autoDisplay: boolean;
            },
            elementId: string
          ): any;
        };
      };
    };
    googleTranslateElementInit: () => void;
  }
}

export default function Navbar() {
  const [click, setClick] = useState(false);
  const [aboutDropdown, setAboutDropdown] = useState(false);
  const [membersDropdown, setMembersDropdown] = useState(false);

  const handleClick = () => {
    setClick(!click);
  };
  const closeMobileMenu = () => setClick(false);

  const onMouseEnterAbout = () => {
    setAboutDropdown(true);
  };
  const onMouseLeaveAbout = () => {
    setAboutDropdown(false);
  };

  const onMouseEnterMembers = () => {
    setMembersDropdown(true);
  };
  const onMouseLeaveMembers = () => {
    setMembersDropdown(false);
  };

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false,
      },
      "google_translate_element"
    );
  };

  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  return (
    <div className="navBar">
      <section
        className={
          click ? "navBar__modal navBar__modal--opened" : "navBar__modal"
        }
        onClick={closeMobileMenu}
      ></section>
      <section className="navBar__section">
        <Link
          to="/"
          className="navBar__link navBar__link--logo"
          onClick={closeMobileMenu}
        >
          <img
            src={waverlyLogo}
            alt="First Free Will Baptist Waverly Logo"
            className="navBar__logo"
          />
        </Link>
        {/* the key is here because FA Icons don't respond to React rerenders. the key forces the component to rerender */}
        <div className="navBar__icon" onClick={handleClick} key={Math.random()}>
          <i className={click ? "fa-solid fa-xmark" : "fa-solid fa-bars"} />
        </div>
        <ul
          className={
            click ? "navBar__list" : "navBar__list navBar__list--hidden"
          }
        >
          <li className="list__item">
            <Link to="/" className="navBar__link" onClick={closeMobileMenu}>
              <span>Home</span>
            </Link>
          </li>
          <li
            className="list__item"
            onMouseEnter={onMouseEnterAbout}
            onMouseLeave={onMouseLeaveAbout}
            onClick={() => setAboutDropdown(!aboutDropdown)}
          >
            <div className="navBar__wrapper">
              <span>about</span>
              <i className="fas fa-caret-down" />
            </div>
            {aboutDropdown && (
              <Dropdown items={AboutItems} closeMobileMenu={closeMobileMenu} />
            )}
          </li>
          <li className="list__item">
            <Link
              to="/services"
              className="navBar__link"
              onClick={closeMobileMenu}
            >
              <span>Services</span>
            </Link>
          </li>
          <li
            className="list__item"
            onMouseEnter={onMouseEnterMembers}
            onMouseLeave={onMouseLeaveMembers}
            onClick={() => setMembersDropdown(!membersDropdown)}
          >
            <div className="navBar__wrapper">
              <span>members</span>
              <i className="fas fa-caret-down" />
            </div>
            {membersDropdown && (
              <Dropdown
                items={MembersItems}
                closeMobileMenu={closeMobileMenu}
              />
            )}
          </li>
          <li className="list__item">
            <Link
              to="https://ffwbcw.chmeetings.com//Giving/E791F5628F15F64A"
              className="navBar__link"
              onClick={closeMobileMenu}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>Give</span>
            </Link>
          </li>
          <li className="list__item list__item--contact">
            <Link
              to="/contact"
              className="navBar__link"
              onClick={closeMobileMenu}
            >
              <ContactButton
                content="Contact"
                classtitle="button button__nav"
              />
            </Link>
          </li>
          <li className="list__item list__item--lang">
            <div id="google_translate_element"></div>
          </li>
        </ul>
      </section>
    </div>
  );
}
