import "./HeroSmall.scss";

export default function HeroSmall(props: {
  content: string;
  extraClass: string;
}) {
  return (
    <div className={`heroSmall ${props.extraClass}`}>
      <h2 className="heroSmall__title">{props.content}</h2>
      <section className="heroSmall__overlay"></section>
    </div>
  );
}
