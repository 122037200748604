import HeroSmall from "../../components/HeroSmall/HeroSmall";
import "./AboutStaff.scss";
import joshHeadshot from "../../assets/images/Headshot.jpg";

export default function AboutStaff() {
  return (
    <div className="about-staff">
      <HeroSmall extraClass="heroSmall__about" content="our staff" />
      <section className="bio-card">
        <img
          src={joshHeadshot}
          alt="Pastor Josh Headshot"
          className="bio-card__img"
        />
        <div className="bio-card__bio">
          <p className="bio-card__header">Meet our pastor</p>
          <h3 className="bio-card__name">Josh Hunter</h3>
          <section className="bio-card__bio">
            <p className="bio-card__text">
              Joshua Hunter has served as the pastor of the church since June
              2023. He grew up around the country, but settled in Tennessee when
              he began attending Welch College, where he has since earned a B.S.
              in Theology, an M.A. in Theology and Ministry, and an M.Div. He is
              now completing a Th.M. from Gordon-Conwell Theological Seminary in
              ancient languages and cultures and speaks at conferences in the
              United States on the background of Scripture. You can find his
              research and follow his studies at{" "}
              <a
                href="https://welch.academia.edu/JHunter"
                className="bio-card__text--link"
              >
                https://welch.academia.edu/JHunter
              </a>
            </p>
            <p className="bio-card__text">
              Apart from his research, Josh enjoys spending time reading,
              writing, and in the community. He loves visiting antique stores
              and local diners, especially those local to Waverly. He has one
              dog, Cassie, a goldendoodle, that can be out and about with Josh
              as well. He loves serving the church and hopes to get to meet you
              should you visit.
            </p>
          </section>
        </div>
      </section>
    </div>
  );
}
