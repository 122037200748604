import HeroSmall from "../../components/HeroSmall/HeroSmall";
import "./AboutHistory.scss";
import schoolHouse from "../../assets/images/schoolHouse.jpg";
import gormanFWB from "../../assets/images/gormanFWB.jpg";
import floodSanctuary from "../../assets/images/floodSanctuary.jpg";
import sanctuary2 from "../../assets/images/Sanctuary2.jpg";
import sanctuary1 from "../../assets/images/Santuary1.jpg";

export default function AboutHistory() {
  return (
    <div className="aboutHistory">
      <HeroSmall extraClass="heroSmall__about" content="our history" />
      <h2 className="aboutHistory__header">
        History of the First Free Will Baptist Church of Waverly
      </h2>
      <section className="aboutHistory__container">
        <article className="aboutHistory__article">
          <div className="article__container">
            <p className="article__text">
              First Free Will Baptist has had a long and eventful history. Old
              Sugar Grove Methodist church was originally organized around 1880
              where it served as a church and school until the county board of
              education tore down the building in 1915 to use the materials to
              construct another school house in Gorman that was permitted to
              continue worship services. From 1915 to 1924, the church offered
              services only when a visiting minister could fill the pulpit.
              These ministers include Presbyterians like J. C. Orr and
              Methodists like A. D. Thompson.
            </p>
            <p className="article__text">
              However, in 1924 L. B. H. Green of Goodlettsville, TN, held a
              revival at the building and officially organized the Gorman Free
              Will Baptist Church, the second church of the Free Will Baptist
              denomination in Humphrey’s County. Rev. Green served as the first
              pastor and Martha Tinnelle the first clerk. In 1926, Rev. Green
              was replaced by W. S. Gentry of Vanleer, TN, though returned as
              pastor a few years later.
            </p>
          </div>
          <img
            className="article__img article__img--school"
            src={schoolHouse}
            alt="Newspaper clipping of 'The Olde School House & Church' Sugar Grove, Trace Creek"
          />
        </article>
        <article className="aboutHistory__article aboutHistory__article--right">
          <div className="article__container">
            <p className="article__text">
              In 1930 the schools were consolidated and the building ceased
              functioning as a school and, in 1955, was deeded to the Deacons of
              Gorman Church, who served as trustees. It was during this time
              that D. A. Hatcher began service as the pastor of the church
              (1934) who served until 1963 when Wallace Hayes assumed the
              pastorate and added the Sunday School rooms, bathrooms, and
              central heat/air.
            </p>
            <p className="article__text">
              Gorman Church began growing under Roy Helms and the leadership
              felt the church had been outgrown. They decided to look for
              property to build upon, and purchased where the county school
              board facilities are currently located in Waverly. The church
              acquired that property and built the physical buildings that are
              there, comprised of the church building and the gymnasium behind
              it. It was during this time that the church voted to change its
              name as it was no longer located within Gorman to the Waverly
              First Free Will Baptist Church. A Christian school operated here
              for a number of years.
            </p>
            <p className="article__text">
              Unfortunately, the church entered a decline and the property
              became too much for the church to maintain. The church decided to
              sell the property, and it was sold to the county to serve as the
              seat for the county board of education. The church then purchased
              their present location and built the current building here.
            </p>
          </div>
          <img
            className="article__img article__img--gorman"
            src={gormanFWB}
            alt="Newspaper clipping of Gorman Free Will Baptist Church"
          />
        </article>
        <article className="aboutHistory__article">
          <div className="article__container">
            <p className="article__text">
              The church was nearly destroyed by a flood that hit Waverly in
              2021. Trailing the COVID pandemic, Trace Creek became flooded by a
              considerable amount of rain in a short time period which backed up
              and sent an unprecedented wall of water crashing through Waverly,
              and right through First Free Will Baptist. Thankfully, the people
              of the town, surrounding cities, and many religious organizations
              rallied together to help the town recover. Despite the building
              being inhabitable for several months, the church never ceased
              having services, gathering under a large tent to worship, work on
              repairing the church building, and coordinate supplies and tools
              for others in the city who needed it.
            </p>
            <p className="article__text">
              The church began holding services inside the building again six
              months after the flood. Having redone everything from eight feet
              down inside the church, it took a lot of time and effort, but God
              blessed through it all.
            </p>
          </div>
          <img
            className="article__img article__img--flood"
            src={floodSanctuary}
            alt="Remains of a flooded church sanctuary"
          />
        </article>
        <article className="aboutHistory__pastorList">
          <img
            className="pastorList__img pastorList__img--desktop"
            src={sanctuary2}
            alt="First Free Will Baptist Church of Waverly's newly repaired sanctuary"
          />
          <img
            className="pastorList__img pastorList__img--mobile"
            src={sanctuary1}
            alt="First Free Will Baptist Church of Waverly's newly repaired sanctuary"
          />
          <div className="pastorList__container">
            <section className="pastorList__gormanList">
              <h3 className="pastorList__header">
                Pastors of Gorman Free Will Baptist Church
              </h3>
              <div className="pastorList__content">
                <section className="pastorList__wrapper pastorList__wrapper--left">
                  <ul className="pastorList__list pastorList__list--name">
                    <li className="pastorList__item">L. B. H. Green</li>
                    <li className="pastorList__item">W. S. Gentry</li>
                    <li className="pastorList__item">L. B. H. Green</li>
                    <li className="pastorList__item">D. A. Hatcher</li>
                    <li className="pastorList__item">Wallace Hayes</li>
                    <li className="pastorList__item">Jerry Newman</li>
                    <li className="pastorList__item">Wallace Hayes</li>
                    <li className="pastorList__item">Gary Carstons</li>
                  </ul>
                  <ul className="pastorList__list pastorList__list--date">
                    <li className="pastorList__item">1924</li>
                    <li className="pastorList__item">1926</li>
                    <li className="pastorList__item">1929</li>
                    <li className="pastorList__item">1934</li>
                    <li className="pastorList__item">1963</li>
                    <li className="pastorList__item">1967</li>
                    <li className="pastorList__item">1968</li>
                    <li className="pastorList__item">1970</li>
                  </ul>
                </section>
                <section className="pastorList__wrapper pastorList__wrapper--right">
                  <ul className="pastorList__list pastorList__list--name">
                    <li className="pastorList__item">Roger Johnson</li>
                    <li className="pastorList__item">George Ludwig</li>
                    <li className="pastorList__item">Arnold Shrewsberry</li>
                    <li className="pastorList__item">Roy E. Helms</li>
                    <li className="pastorList__item">Tim Young</li>
                    <li className="pastorList__item">Dr. Johnny Sexton</li>
                    <li className="pastorList__item">Roy Lester</li>
                    <li className="pastorList__item">Dexter Guin</li>
                  </ul>
                  <ul className="pastorList__list pastorList__list--date">
                    <li className="pastorList__item">1971</li>
                    <li className="pastorList__item">1972</li>
                    <li className="pastorList__item">1974</li>
                    <li className="pastorList__item">1975</li>
                    <li className="pastorList__item">1984</li>
                    <li className="pastorList__item">1985</li>
                    <li className="pastorList__item">1989</li>
                    <li className="pastorList__item">1991</li>
                  </ul>
                </section>
              </div>
            </section>
            <section className="pastorList__waverlyList">
              <h3 className="pastorList__header">
                Pastors of First Free Will Baptist Church of Waverly
              </h3>
              <div className="pastorList__content">
                <section className="pastorList__wrapper pastorList__wrapper--bottom">
                  <ul className="pastorList__list pastorList__list--name">
                    <li className="pastorList__item">Eddie Hopkins</li>
                    <li className="pastorList__item">Rick Kennedy</li>
                    <li className="pastorList__item">Fred Jones</li>
                    <li className="pastorList__item">Rick Kennedy</li>
                    <li className="pastorList__item">Brad Seaton</li>
                    <li className="pastorList__item">Joshua Hunter</li>
                  </ul>
                  <ul className="pastorList__list pastorList__list--date">
                    <li className="pastorList__item">1994</li>
                    <li className="pastorList__item">2004</li>
                    <li className="pastorList__item">2006</li>
                    <li className="pastorList__item">2008</li>
                    <li className="pastorList__item">2013</li>
                    <li className="pastorList__item">2023</li>
                  </ul>
                </section>
              </div>
            </section>
          </div>
        </article>
      </section>
    </div>
  );
}
