import "./ServicesList.scss";
import clockSVG from "../../assets/icons/clock.svg";

export default function ServicesList() {
  return (
    <section className="servicesList">
      <h2 className="servicesList__header">our services</h2>
      <div className="servicesList__services">
        <section className="servicesList__card servicesList__card--odd">
          <h3 className="serviceCard__title">Sunday school</h3>
          <p className="serviceCard__desc">
            Join us for an in-depth study of a book of the Bible, its history,
            and its significance.
          </p>
          <div className="serviceCard__schedule">
            <img src={clockSVG} alt="Clock" className="serviceCard__clock" />
            <h4 className="serviceCard__time">Sunday 10:00 am</h4>
          </div>
        </section>
        <section className="servicesList__card">
          <h3 className="serviceCard__title">worship service</h3>
          <p className="serviceCard__desc">
            We gather to worship through prayer, singing, Scripture reading,
            giving, and preaching the Word.
          </p>
          <div className="serviceCard__schedule">
            <img src={clockSVG} alt="Clock" className="serviceCard__clock" />
            <h4 className="serviceCard__time">Sunday 11:00 am</h4>
          </div>
        </section>
        <section className="servicesList__card servicesList__card--odd">
          <h3 className="serviceCard__title">Wednesday night bible study</h3>
          <p className="serviceCard__desc">
            Join us as we go chapter by chapter through the book of Matthew
            understanding its context, history, and significance.
          </p>
          <div className="serviceCard__schedule">
            <img src={clockSVG} alt="Clock" className="serviceCard__clock" />
            <h4 className="serviceCard__time">Wednesday 6:00 pm</h4>
          </div>
        </section>
        <section className="servicesList__card">
          <h3 className="serviceCard__title">Wednesday night youth group</h3>
          <p className="serviceCard__desc">
            Come along as our youth work their way through the Gospel Project
            study series.
          </p>
          <div className="serviceCard__schedule">
            <img src={clockSVG} alt="Clock" className="serviceCard__clock" />
            <h4 className="serviceCard__time">Wednesday 6:00 pm</h4>
          </div>
        </section>
        <section className="servicesList__card servicesList__card--odd">
          <h3 className="serviceCard__title">Wednesday night Q&A</h3>
          <p className="serviceCard__desc">
            Join us for a open discussion where we answer various questions
            about the book of Matthew.
          </p>
          <div className="serviceCard__schedule">
            <img src={clockSVG} alt="Clock" className="serviceCard__clock" />
            <h4 className="serviceCard__time">Wednesday 7:00 pm</h4>
          </div>
        </section>
      </div>
    </section>
  );
}
