import FAQList from "../../components/FAQList/FAQList";
import HeroSmall from "../../components/HeroSmall/HeroSmall";
import MapCard from "../../components/MapCard/MapCard";
import ServicesList from "../../components/ServicesList/ServicesList";
import "./ServicesPage.scss";

export default function ServicesPage() {
  return (
    <div>
      <HeroSmall extraClass="heroSmall__services" content="plan your visit" />
      <ServicesList />
      <MapCard />
      <FAQList />
    </div>
  );
}
