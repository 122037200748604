import HeroSmall from "../../components/HeroSmall/HeroSmall";
import MinistryCard from "../../components/MinistryCard/MinistryCard";
import "./MinistriesPage.scss";

export default function MinistriesPage() {
  return (
    <div className="ministriesPage">
      <HeroSmall extraClass="heroSmall__ministries" content="ministries" />
      <section className="ministriesPage__list">
        <MinistryCard
          title="Worship Services"
          desc="Worship lies at the heart of our community, and so our worship service on Sunday morning is the main service of the church. We gather together as one family to worship the Triune God through prayer, singing, Scripture reading, giving, and studying the word. In our singing, we aim for songs that are biblical and teach something about God, whether 500, 50, or 5 years old, quick or slow. In preaching, our pastor strives for exegetical exposition of the Scriptures, meaning he tries to stay as close to the biblical text as possible and aim for uncovering the original meaning and then apply it to the modern day."
          extraClass="handsCool"
          cardClass="ministryCard"
        />
        <MinistryCard
          title="Bible studies & classes"
          desc="We have a variety of Bible studies and classes available for our members. We currently offer a Sunday School course going chapter by chapter through books of the Bible, and then two classes on Wednesday on different topics. Occasionally, we also offer group discussions on different interests of members of the congregation and conferences in which scholars are invited to speak on different subjects of the Bible."
          extraClass="bibleStudy"
          cardClass="ministryCard ministryCard--right"
        />
        <MinistryCard
          title="Singing & Music concerts"
          desc="Many of our folks in the church love to hear good Christian singing, and so members of our church get together to frequently attend concerts in the surrounding area. In addition, we bring a singing group to the church about four to five times a year to offer a donation-based community concert."
          extraClass="hymnal"
          cardClass="ministryCard"
        />
        <MinistryCard
          title="Women's Ministry"
          desc="Tuesdays @ 6 PM - A time for women to gather for fellowship and discipleship through studying the Bible."
          extraClass="women"
          cardClass="ministryCard ministryCard--right"
        />
        <MinistryCard
          title="Conferences & lectures"
          desc="In addition to concerts, we also put on conferences in which biblical experts are invited to share their knowledge on a particular subject. These conferences are centered on specific Scriptural themes and open to the public, though we also offer occasional lectures from notable scholars or mental health professionals which cover more specific topics and questions, also open to any who wish to come."
          extraClass="takingNotes"
          cardClass="ministryCard"
        />
        <MinistryCard
          title="Library ministry"
          desc="One of our newest ministries is a well-stocked library inside the church. This library has books, movies, board games, and online sources all checked by the church for its sound biblical worldview. Church attenders and their children are welcome to utilize the library, and may check-out books to take home with them as well."
          extraClass="handBook"
          cardClass="ministryCard ministryCard--right"
        />
        <MinistryCard
          title="Fellowship"
          desc="We are a family here at First Free Will, and we like to get together! Whether over a monthly game night, breakfast or potluck, or to attend the aforementioned concerts or go to a museum, we enjoy spending time in fellowship as a family."
          extraClass="fellowship"
          cardClass="ministryCard"
        />
        <MinistryCard
          title="Community involvement"
          desc="Christ commanded us to make disciples, and so we are missionaries, whether abroad, or right here in Waverly. We partner with some community organizations to serve those in the community, as well as do our own service and benevolence projects to assist those in need. One of our greatest offerings to the community is our counseling ministry, mentioned below."
          extraClass="volunteer"
          cardClass="ministryCard ministryCard--right"
        />
        <MinistryCard
          title="Shepherd's Servants"
          desc="Do you need physical help around your home? This ministry offers free help for those in need, from raking leaves and building stairs to cleaning hard-to-reach places and doing laundry. Email the church to reach out if you are in need of help."
          extraClass="construction"
          cardClass="ministryCard"
        />
        <MinistryCard
          title="Counseling ministry"
          desc="At First Free Will Baptist we believe God made people as thinking, feeling, and acting beings, and so we believe mental and emotional health is just as important as physical. As such, we have made partnerships with professional counselors whom the church will refer members of the church and surrounding community to. The church will assist in paying up to 6 sessions with the counselor, whether online or in-person."
          extraClass="prayer"
          cardClass="ministryCard ministryCard--right"
        />
      </section>
    </div>
  );
}
